import { NavLink, Link } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import ContentfulUtil from "./util/ContentfulUtil";

export default function NavBar() {
  const [currentSeason, setCurrentSeason] = useState([]);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isAboutUsExpanded, setIsAboutUsExpanded] = useState(false);
  const [isVolunteerExpanded, setIsVolunteerExpanded] = useState(false);
  const navigationButtonRef = useRef();
  const aboutUsButtonRef = useRef();
  const volunteerButtonRef = useRef();

  useEffect(()=>{
    const fetchData = async () => {
        const currentSeason = await ContentfulUtil.getCurrentSeasonByStatus();
        setCurrentSeason(currentSeason)
    };
  
    fetchData();
  },[])

  useEffect(() => {
    const toggleCollapseClass = (ref, shouldCollapse) => {
      if (ref.current) {
        if (shouldCollapse) {
          ref.current.classList.add("collapse");
        } else {
          ref.current.classList.remove("collapse");
        }
      }
    };

    toggleCollapseClass(navigationButtonRef, !isNavExpanded);
  }, [isNavExpanded]);

  useEffect(() => {
    const toggleCollapseClass = (ref, shouldCollapse) => {
      if (ref.current) {
        if (shouldCollapse) {
          ref.current.classList.add("collapse");
        } else {
          ref.current.classList.remove("collapse");
        }
      }
    };

    toggleCollapseClass(aboutUsButtonRef, !isAboutUsExpanded);
  }, [isAboutUsExpanded]);

  useEffect(() => {
    const toggleCollapseClass = (ref, shouldCollapse) => {
      if (ref.current) {
        if (shouldCollapse) {
          ref.current.classList.add("collapse");
        } else {
          ref.current.classList.remove("collapse");
        }
      }
    };

    toggleCollapseClass(volunteerButtonRef, !isVolunteerExpanded);
  }, [isVolunteerExpanded]);

  const handleToggleNavExpanded = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  const handleToggleAboutUsExpanded = () => {
    setIsAboutUsExpanded(!isAboutUsExpanded);
    setIsVolunteerExpanded(false);
  };

  const handleToggleVolunteerExpanded = () => {
    setIsVolunteerExpanded(!isVolunteerExpanded);
    setIsAboutUsExpanded(false);
  };

  return (
    <div className="navigation w-100">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark p-0">
          <a className="navbar-brand" href="/">Spotlight Players</a>
          <button className="navbar-toggler rounded-0" type="button" aria-controls="navigation" aria-expanded={isNavExpanded} aria-label="Toggle navigation" onClick={handleToggleNavExpanded}>
            <span className="navbar-toggler-icon" />
          </button>
          <div ref={navigationButtonRef} className={`collapse navbar-collapse${isNavExpanded ? '' : ' collapse'}`} id="navigation">
            <ul className="navbar-nav ml-auto text-center">
              <li className="nav-item">
                <NavLink className="nav-item nav-link" to="/" onClick={handleToggleNavExpanded}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item dropdown view">
                <div className="nav-link" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded={isAboutUsExpanded} onClick={handleToggleAboutUsExpanded}>
                  About Us
                  <div ref={aboutUsButtonRef} id="aboutUs" className={`nav-hidden${isAboutUsExpanded ? '' : ' collapse'}`} style={{ backgroundColor: "white", marginTop: "15px" }} aria-labelledby="aboutUsDropdown" onClick={handleToggleNavExpanded}>
                    <Link className="nav-link" style={{ color: "#1a1a37" }} to="/about">About Us</Link>
                    <Link className="nav-link" style={{ color: "#1a1a37" }} to="/jrplayers">Jr Players</Link>
                    <Link className="nav-link" style={{ color: "#1a1a37" }} to="/past-seasons">Past Seasons</Link>
                    <Link className="nav-link" style={{ color: "#1a1a37" }} to="/policies-and-practices">Policies & Practices</Link>
                    {/* <Link className="nav-link" style={{ color: "#1a1a37" }} to="/contact-us">Contact Us</Link> */}
                  </div>
                </div>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/about">About Us</Link>
                  <Link className="dropdown-item" to="/jrplayers">Jr Players</Link>
                  <Link className="dropdown-item" to="/past-seasons">Past Seasons</Link>
                  <Link className="dropdown-item" to="/policies-and-practices">Policies & Practices</Link>
                  {/* <Link className="dropdown-item" to="/contact-us">Contact Us</Link> */}
                </div>
              </li>
              <li className="nav-item dropdown view">
                <Link className="nav-link" to="/current-season" onClick={handleToggleNavExpanded}>
                  Current Season
                </Link>
                <div className={`dropdown-menu${isNavExpanded ? '' : ' collapse'}`} aria-labelledby="navbarDropdown">
                  {currentSeason.map((show) => (
                    <Link key={show.sys.id} className="dropdown-item" to={show.getLink()}>
                      {show.fields.name}
                    </Link>
                  ))}
                </div>
              </li>
              <li className="nav-item dropdown view">
                <div className="nav-link" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded={isVolunteerExpanded} onClick={handleToggleVolunteerExpanded}>
                  Get Involved
                  <div ref={volunteerButtonRef} id="volunteer" className={`nav-hidden${isVolunteerExpanded ? '' : ' collapse'}`} style={{ backgroundColor: "white", marginTop: "15px" }} aria-labelledby="volunteerDropdown" onClick={handleToggleNavExpanded}>
                    <Link className="nav-link" style={{ color: "#1a1a37" }} to="/auditions">Auditions</Link>
                    <Link className="nav-link" style={{ color: "#1a1a37" }} to="/volunteer">Volunteer</Link>
                    <Link className="nav-link" style={{ color: "#1a1a37" }} to="/become-a-member">Become A Member</Link>
                    <Link className="nav-link" style={{ color: "#1a1a37" }} to="/donate">Support</Link>
                    <Link className="nav-link" style={{ color: "#1a1a37" }} to="/production-team">Join a Production Team</Link>
                  </div>
                </div>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/auditions">Auditions</Link>
                  <Link className="dropdown-item" to="/volunteer">Volunteer</Link>
                  <Link className="dropdown-item" to="/become-a-member">Become A Member</Link>
                  <Link className="dropdown-item" to="/donate">Support</Link>
                  <Link className="dropdown-item" to="/production-team">Join a Production Team</Link>
                </div>
              </li>
              <li className="nav-item">
                <NavLink className="nav-item nav-link" to="/tickets" onClick={handleToggleNavExpanded}>
                  Tickets
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}