import PageTitle from '../../components/PageTitle';
import PageContent from '../../components/PageContent';

function ProductionTeam() {
  return (<>
    <PageTitle>
      Join a Production Team     
      {/* <p className="text-lighten">Support The Spotlight Players by becoming a member today!</p> */}
    </PageTitle>
    <PageContent>
        <div className="row">
          <div className="col-12">
            <h3>Director</h3>         
            <p>A theater director is responsible for overseeing the artistic vision and execution of a theatrical production. They work closely with actors, designers, and other production staff to ensure that the play or musical is brought to life in a compelling and impactful way. The director must have a strong understanding of the script, as well as the ability to communicate their vision effectively to the cast and crew. They are also responsible for managing the rehearsal process, making decisions about staging and blocking, and ensuring that the final product meets the artistic and technical standards of the theater.</p>
            <a className='btn btn-secondary btn-center' href="https://docs.google.com/forms/d/e/1FAIpQLSfPVT-IMDuPs6QwnOYwqkK0MlSJeIi4gzYwIPFuo7cn1hX91w/viewform?usp=sf_link" target={'_blank'}>Apply to be a Director</a>
            {/* <a className='btn btn-secondary btn-center' href="https://forms.gle/43XM5x6bEFWMka4JA" target={'_blank'}>Suggest a Show</a> */}


            <hr></hr>
            <h3>Music Director</h3>         
            <p>A music director is responsible for leading the musical aspects of a theatrical production, including choosing and arranging audition music, conducting rehearsals and performances, and working closely with the director and cast to ensure that the music aligns with the overall vision of the production. They may also be responsible for selecting and managing musicians. A successful music director should have strong musical skills, excellent communication and collaboration abilities, and a deep understanding of the theater production.</p>
            <a className='btn btn-secondary btn-center' href="https://docs.google.com/forms/d/e/1FAIpQLSecr8-zLEnGYEhZlyToBIreG7iqmYGJkWGhWxP0E7F6pmsRVQ/viewform?usp=sf_link" target={'_blank'}>Apply to be a Music Director</a>
            {/* <a className='btn btn-secondary btn-center' href="https://forms.gle/43XM5x6bEFWMka4JA" target={'_blank'}>Suggest a Show</a> */}


            <hr></hr>
            <h3>Producer</h3>         
            <p>A producer is responsible for overseeing the production of a theatrical performance. They work closely with the director, cast, and crew to ensure that the show runs smoothly and meets the artistic vision of the production. This involves managing budgets, coordinating schedules, organizing rehearsals, securing funding, and marketing the show to potential audiences. The ideal candidate should have a strong understanding of theater production and possess excellent communication, leadership, and organizational skills.</p>
            <a className='btn btn-secondary btn-center' href="https://docs.google.com/forms/d/e/1FAIpQLSenvMNWQMbp2s3sAWCgv57MXxl3-BcuVppyrG7FyD3wFQJBqw/viewform?usp=sf_link" target={'_blank'}>Apply to be a Producer</a>
            {/* <a className='btn btn-secondary btn-center' href="https://forms.gle/43XM5x6bEFWMka4JA" target={'_blank'}>Suggest a Show</a> */}

            <hr></hr>
            <h3>Choreographer</h3>         
            <p>A choreographer is responsible for creating and overseeing the dance and movement sequences in a theatrical production. They work closely with the director and other members of the creative team to ensure that the choreography enhances the story and characters being portrayed on stage. The choreographer may also work with actors to help them develop their dance skills and ensure that they are able to perform the choreography accurately. This position requires a strong background in dance and choreography, as well as excellent communication and collaboration skills.</p>
            <a className='btn btn-secondary btn-center' href="https://docs.google.com/forms/d/e/1FAIpQLSeHVPP-zVGyssUBc9UJpKB-EBLOvseMYwBdknqA9E8ECq5Y4w/viewform?usp=sf_link" target={'_blank'}>Apply to be a Choreographer</a>
            {/* <a className='btn btn-secondary btn-center' href="https://forms.gle/43XM5x6bEFWMka4JA" target={'_blank'}>Suggest a Show</a> */}

          </div>
        </div>
    </PageContent>    
  </>);
}

export default ProductionTeam