import {useState,useEffect} from 'react';

import Event from '../components/Event';
import ContentfulUtil from "../util/ContentfulUtil";
import PageTitle from '../components/PageTitle';
import PageContent from '../components/PageContent';

function FutureSeason() {   
    const [futureSeason, setFutureSeason] = useState([]);
    
    useEffect(()=>{
        const fetchData = async () => {
            const futureSeason = await ContentfulUtil.getFutureSeasonByStatus();
            setFutureSeason(futureSeason)
        };
        fetchData();
    },[])

    return (<>
        <PageTitle>Next Season Preview</PageTitle>
        <PageContent>
            <div className="row justify-content-center">
                {futureSeason.map((event)=>{                  
                    return <Event 
                        key={event.sys.id}
                        title={event.fields.name} 
                        location={event.fields.location}  
                        firstDay={event.firstPerformanceDate}
                        imgSrc={event.fields.squareImage.fields.file.url} 
                        production={event.fields.productionGroup} 
                        // link={event.getLink()} 
                        dates={event.performances} >
                    </Event>
                })}
            </div>
        </PageContent>
    </>);
}

export default FutureSeason