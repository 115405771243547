import PageTitle from "../../components/PageTitle";
import PageContent from "../../components/PageContent";

function PoliciesAndPractices() {
  return (<>
    <PageTitle>
      <div>
        Policies and Practices
        <p className="text-lighten">Below is a list of all our policies, find out how you can get involved today!</p>
      </div>
    </PageTitle>
    <PageContent>
        <div className="row">
          <div className="col-12">
            <h4>CONSTITUTION AND BY-LAWS</h4>
            <p>As a non-profit organization, we have outlined a clear Mission Statement to help guide the choices and endeavors made by Spotlight Players as a community presence. To learn about our organization, its mission statement, and its structure, download the Constitution and By-Laws document <a href="https://drive.google.com/file/d/1qjJQbBvmbfbZSkiVGVMYTIprheGOXGl-/view?usp=sharing" target={"_blank"}>here</a> (updated on 05/22/2019).</p>
            
            <h4>SHOW POLICIES AND PRACTICES</h4>
            <p>Community members and show production staff alike will find the policies related to how Spotlight Players carries-out a performance, from start to finish a useful resource. Whether you are involved in a show, or considering participating, the information outlined here is informative and structured. Download our Show Policies & Practices <a href="https://drive.google.com/file/d/1WSIBCHLWK8IW0GTZLcEbNNb7V2VAYPMz/view?usp=sharing" target={"_blank"}>here</a>. (Updated Nov. 1, 2019)</p>
          
            <h4>OPERATION POLICY AND PRACTICES</h4>
            <p>At Spotlight Players, we set certain benchmarks for standards of ethics from our organization as a whole, and from our membership as representatives of our group. These policies and practices help us measure our success as we strive to operate ethically in every aspect of our business. Download our Operation Policy & Practices <a href="https://drive.google.com/file/d/1i2F_qcOmlMBycQO5a0zJOAGg14V6MHaB/view?usp=sharing" target={"_blank"}>here</a>. (Updated Nov. 1, 2019)</p>

            <h4>CONFLICT OF INTEREST POLICY AND PRACTICES</h4>
            <p>One of the challenges of any non-profit is the obligation to recognize and mitigate the occurrence of conflicts of interest with respect to various aspects of the organization. In order to keep our own house in order, we have outlined the following policy. To read our Conflict of Interest Policy & Practices, click <a href="https://drive.google.com/file/d/1nM5Qvcn-_vXeS_ZvW-kkJyP_SaDeLLni/view?usp=sharing" target={"_blank"}>here</a>.</p>

            <h4>COVID-19 MASKING POLICY</h4>
            <p>Spotlight Players follows the Village Theater policy regarding wearing masks. According to the Village Theater, effective February 1, 2022, “Face masks are no longer required, but are highly recommended, for all guests inside The Village Theater, regardless of vaccination status. In addition, Social Distancing will also be observed. For additional information, contact The Village Theater Box Office at 734/394-5300.” (Updated June 11, 2022)</p>
            <div  className="imgPolicy">
              <div >
                <h4>PARKING POLICY</h4>
                <img className="parkingImg" src="images/VillageTheaterParking.jpg" alt="Map of Village Theater Parking"></img>
              </div>
              <p className="parkingPolicy">Parking at the Village Theater can be tricky at times, especially when events at the theater overlap with rehearsals for shows. The areas shaded in GREEN are spots where we are permitted to park. The area shaded in RED is the lot immediately behind the theater and is reserved for apartment residents and theater staff only. Please familiarize yourself with the GREEN spaces and only park in those.</p>
            </div>

            <h4>SET CONSTRUCTION POLICY</h4>
            <p>In order to care for Spotlight Players’ property and ensure longevity of our stock of set construction materials, we have initiated a new policy to help guarantee set staples like flats and trucks enjoy a very long and productive life! Please download our Set Construction Policy <a href="https://drive.google.com/file/d/14VbQr3X64B-c6pu3AR3e_hRijsivma0x/view?usp=sharing" target={"_blank"}>here</a>.</p>

            <h4>VIDEO AUDITION SUBMISSIONS POLICY</h4>
            <p>In an effort to accommodate the busy schedules of community members, Spotlight Players permits the submission of pre-recorded auditions for those unable to attend a specific audition in person. Please download our Video Audition Submission Policy <a href="https://drive.google.com/file/d/1wd8jBlXzd8uYxLdzzWFtPVScCp3PetrY/view?usp=sharing" target={"_blank"}>here</a>.</p>

            <h4>COSTUME AND PROPS INVENTORY POLICY</h4>
            <p>As a community organization, we embrace the opportunity to help support other performing groups throughout the area, as we earnestly strive for excellence within Spotlight Players. In order to facilitate that support, we frequently rent out or even sell set pieces or costumes from prior shows. If you are interested in some of our pieces, email the <a href="mailto:spotlightplayers.board@gmail.com">Spotlight Board</a></p>

            <h4>MEMBERSHIP POLICY</h4>
            <p>Learn about the particulars of our membership and what it entails. We hope that you will be encouraged to renew your membership from year to year, regardless of participation in the shows for any given season. Membership renewal helps support our efforts in an on-going basis. View our Membership Policy <a href="https://drive.google.com/file/d/1jibuT8JQdYBt7Qu_yy0RAaAfl-ReOcZa/view?usp=sharing" target={"_blank"}>here</a>.</p>

            <h4>CODE OF CONDUCT POLICY</h4>
            <p>As a community organization that is run by volunteers, we believe it is important to insist on a certain standard of behavior from our members. We want our group represented in a positive manner both on and off the stage. We also want to respect those volunteers who work so hard and donate so much of their time and resources to the interests of Spotlight Players. Signing this document is mandatory for membership in Spotlight Players. Download our Code of Conduct <a href="https://drive.google.com/file/d/1AimALZGsn1H1D-H4aek8p8DBASLb9IOH/view?usp=sharing" target={"_blank"}>here</a>.</p>

            <h4>CANTON TOWNSHIP BACKGROUND FORM</h4>
            <p>Spotlight Players uses Canton Township facilities for all of our rehearsals and performances. All members of Spotlight are required to submit a Canton Township Leisure Services Background Form with your membership. This form is to be completed once per year and is submitted directly to Canton Township, they are not kept by Spotlight Players. Download Canton Township background check authorization form <a href="https://drive.google.com/file/d/1JOdtWsWR46EsW8URcybKvDaGrnRgvCZa/view?usp=sharing" target={"_blank"}>here</a></p>

            <p>Please also review Canton Leisure Services Code of Conduct and suspension policy <a href="https://drive.google.com/file/d/1jcW78gFaFrTJTxVbt6kUoiWN3YPItW3M/view?usp=sharing" target={"_blank"}>here</a>.</p>
             
          </div>
        </div>
    </PageContent>
  </>);
}

export default PoliciesAndPractices