export default class Show {
    constructor(show){
        const showWithPerformances = this.#addPerformances(show);
        const showWithPerformancesAndAuditions = this.#addAuditions(showWithPerformances);

        Object.keys(showWithPerformancesAndAuditions).forEach((key)=>{
            this[key] = showWithPerformancesAndAuditions[key];
        });
    }

    getLink(){
        return '/show/' + this.fields.url;
    }

    #addPerformances(show){
        const today = new Date();
        const performances = [];
        let canShowTickets = false;
        let firstPerformanceDate = new Date(show.fields.performance1)
        let lastPerformanceDate = null; 
    
        for (let i = 1; i <= 6; i++) {
            const performanceField = show.fields[`performance${i}`];
            if (performanceField) {
                performances.push(performanceField);
                const performanceDate = new Date(performanceField);
                if (performanceDate >= today) {
                    canShowTickets = true;
                }

                if (performanceDate >= lastPerformanceDate){
                    lastPerformanceDate = performanceDate;
                }
            }
        }
    
        if (!show.fields.ticketPurchaseUrl) {
            canShowTickets = false;
        }
    
        const updatedShow = {
            ...show,
            performances,
            canShowTickets,
            firstPerformanceDate,
            lastPerformanceDate
        };
        return updatedShow;
    }

    #addAuditions(show){
        const today = new Date();
        const auditions = [];
        let canShowAuditions = false;
        let firstAuditionDate = new Date(show.fields.audition1)
        let lastAuditionDate = null; 
    
        for (let i = 1; i <= 3; i++) {
            const auditionField = show.fields[`audition${i}`];
            if (auditionField) {
                auditions.push(auditionField);
                const auditionDate = new Date(auditionField);
                if (auditionDate >= today) {
                    canShowAuditions = true;
                }

                if (auditionDate >= lastAuditionDate){
                    lastAuditionDate = auditionDate;
                }
            }
        }
    
        const updatedShow = {
            ...show,
            auditions,
            canShowAuditions,
            firstAuditionDate,
            lastAuditionDate
        };
        return updatedShow;
    }
}