import PageTitle from "../../components/PageTitle";
import PageContent from "../../components/PageContent";
import BoardMember from "../../components/aboutUs/BoardMember";
import BoardMeetings from "../../components/aboutUs/BoardMeetings";

 function AboutUs() {
  const boardMembers = [
    { name: 'Nicci Dreyer', position: 'President' , imgSrc:'images/directors/Nicci.png'},
    { name: 'Shannon Tracy', position: 'Vice President', imgSrc:'images/directors/Shannon.png' },
    { name: 'Jennifer Plier', position: 'Treasurer', imgSrc:'images/directors/Jen.png' },
    { name: 'Melissa Francis', position: 'Secretary', imgSrc:'images/directors/Melissa.jpg' },
    { name: 'Anthony Pierznowski', position: 'Board Member', imgSrc:'images/directors/Anthony.png' },
    { name: 'Brian George', position: 'Board Member', imgSrc:'images/directors/Brian.jpg' },
    { name: 'Jeff Pierznowski', position: 'Board Member', imgSrc:'images/directors/Jeff.png' },
    { name: 'Marylee Pierzynowski', position: 'Board Member', imgSrc:'images/directors/Marylee.jpg' },
    { name: 'Paul Hardy', position: 'Board Member', imgSrc:'images/directors/Paul.jpg' },
    { name: 'Tim Brunett', position: 'Board Member', imgSrc:'images/directors/Tim.png' },
    { name: 'Wendy Sielaff', position: 'Board Member', imgSrc:'images/directors/Wendy.png' },

  ];

  const dates = [
    { month: 'January', date: 11 },
    { month: 'February', date: 8 },
    { month: 'March', date: 8 },
    { month: 'April', date: 12 },
    { month: 'May', date: 10 },
    { month: 'June', date: 14 },
    { month: 'July', date: 12 },
    { month: 'August', date: 9 },
    { month: 'September', date: 6 },
    { month: 'October', date: 4 },
    { month: 'November', date: 1 },
    { month: 'December', date: 6 },
  ];

  return (<>
    <PageTitle>
      <div>
        About Us
      </div>
    </PageTitle>
    <PageContent>
      <div className="row">
        <div className="col-12">
          <p>Spotlight Players are partnered with <a href="http://www.canton-mi.org/villagetheater/">The Village Theater</a> at Cherry Hill and Ridge Road in Canton. Our partnership with the theater provides us with the privilege of rehearsing and performing inside the beautiful state-of-the-art facilities that are as unique as they are luxurious. We thank The Village Theater for its support and promotion of our mission statement as we have striven to bring quality live theater experiences to audiences for almost a decade.</p>
          <p>Theater Address:<br />50400 Cherry Hill Road, Canton, MI 48187</p>
          <h4>MISSION STATEMENT</h4>
          <p>Our mission is to provide an avenue for the community to participate in the performing arts; to develop the skills of our members and others in the theater arts (acting, directing, technical and administrative); to offer a variety of entertaining and cultural productions; and, to expand the number of people exposed to the arts through theatrical productions and activities.</p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12">
          <h2 className="section-title">Our Board</h2>
        </div>
        {boardMembers.map((member, index) => (
          <BoardMember key={index} name={member.name} position={member.position} imgSrc={member.imgSrc} />
        ))}
      </div>
      <BoardMeetings dates={dates}></BoardMeetings>
    </PageContent>
  </>);
}

export default AboutUs