import moment from 'moment';

export function dateCombiner(dates){
    let concatenatedDates = {};
    dates.forEach((propDate)=>{
        let date = moment(propDate);
        let month = date.format("MMMM")
        let time = date.format("LT")
        let day = [date.format("D")]

        if (concatenatedDates[month] === undefined) {
            concatenatedDates[month] = {
                [time] : []
            }
        } 

        if (concatenatedDates[month][time] === undefined) {
            concatenatedDates[month][time] = [];
        }

        concatenatedDates[month][time].push(day);
    })

    return concatenatedDates;
}

/*{
"March" : {
    "3:30" : [ 1,2],
    "7:30" : [ 2,4]
"April" : {
    "5:30" : [ 5,8],
    "6:00" : [ 6,9]
}*/
