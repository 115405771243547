import { useState, useEffect } from 'react';

import Event from "../components/Event";
import ContentfulUtil from '../util/ContentfulUtil';
import PageTitle from '../components/PageTitle';
import PageContent from '../components/PageContent';

function Tickets() {
  const [availableTickets, setAvailableTickets] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentSeason = await ContentfulUtil.getCurrentSeason();
        const filteredTickets = currentSeason.filter(show => show.canShowTickets);
        setAvailableTickets(filteredTickets);
      } catch (error) {
        console.error('Error fetching ticketing details:', error);
      }
    };

    fetchData();
  }, []);

  let ticketEvents = availableTickets.length > 0 ? (
    availableTickets.map(event => (
      <Event
        key={event.sys.id}
        title={event.fields.name} 
        location={event.fields.location}  
        firstDay={event.firstPerformanceDate}
        imgSrc={event.fields.squareImage.fields.file.url} 
        production={event.fields.productionGroup} 
        link={event.fields.ticketPurchaseUrl} 
        dates={event.performances}
        text="Buy Tickets"
      />
    ))
  ) : (
    <p className="text-center">I'm sorry but there are no tickets available for upcoming shows, please check back soon!</p>
  );

  return (
    <>
      <PageTitle>Tickets</PageTitle>
      <PageContent>
        <div className="row justify-content-center">
          {ticketEvents}
        </div>
      </PageContent>
    </>
  );
}

export default Tickets;