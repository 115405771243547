export default function VolunteerCard({ id, imageSrc, title, description, buttonText, buttonLink, target, position }) {
    return (
      <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
        <div className="card rounded-0 border-top-0 border-left-0 border-right-0 border-bottom-0">
          {id && <div className="anchor" id={id}></div>}
          <img className="card-img-top rounded-0" src={imageSrc}/>
          <div className="card-body">
            <p className="mb-1">{position}</p>
            <h4 className="card-title mb-3">{title}</h4>
            {description}
            <div className="btn-center">
              <a href={buttonLink} target={target} className="btn btn-primary btn-sm">
                {buttonText}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }