export default function BoardMember({name, position, imgSrc}){
    return (
        <div className="col-lg-4 col-sm-6 mb-5 mb-lg-0">
            <div className="card border-0 rounded-0 hover-shadow">
            <img className="card-img-top rounded-0" src={imgSrc} alt="board member" />
            <div className="card-body">
                <div className="card-title h4"><b>{name}</b></div>
                <div className="d-flex justify-content-between">
                    <span>{position}</span>
                </div>
            </div>
            </div>
        </div>
    );
}