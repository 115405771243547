import PageTitle from '../../components/PageTitle';
import PageContent from '../../components/PageContent';

function Support() {
  return (<>
    <PageTitle>
      Support     
      {/* <p className="text-lighten">Support The Spotlight Players by becoming a member today!</p> */}
    </PageTitle>
    <PageContent>
        <div className="row">
          <div className="col-12">
            <h3>Donate</h3>         
            <p>Spotlight Players is a 501 (c) 3 nonprofit, community theater company based in Canton.  Last season was a great success! Over 8000 people from the Plymouth-Canton area, as well as neighboring communities enjoyed themselves at a Spotlight Players performance.</p>
            <p>Spotlight Players is parent to many umbrella groups, providing theater for all ages in a variety of performances ranging from main stage to studio shows, from musicals to straight shows, and from cabarets to murder mystery dinner theater.</p>
            <p>As you can imagine, it is increasingly difficult to support the cost of quality theater and day-to-day expenses on ticket sales alone. We depend on the generosity of individuals and sponsors to allow us to continue to perform in and for the community.</p>
            <p>Spotlight Players is asking you to consider donating for this Season. Spotlight Players is grateful to have continued support throughout the years from individuals, businesses and organizations. We invite you to become involved and support live local theater.</p>
            
            <div className='donateButtons'>
              <a className='btn btn-primary btn-center' href="https://www.paypal.com/donate/?hosted_button_id=MRF3C63TX4EYA" target={'_blank'}>Donate via PayPal or Credit Card</a>
            </div>
            <div className='donateButtons'>
            <a className='btn btn-primary btn-center' href="https://account.venmo.com/payment-link?audience=friends&recipients=%2CspotlightMI&txn=pay" target={'_blank'}>Donate via Venmo</a>
            </div>
          

            <hr></hr>
            <h3>Kroger Community Rewards Program</h3>
            <p>If you are a Kroger shopper, please take a moment to either renew your enrollment with Spotlight Players Community Rewards or sign-up for enrollment.</p>
            <p>All you need to do is create an account (if a first-timer) and then select to enroll Spotlight Players to receive a percentage to be donated by Kroger every time you shop!</p>
            <p>Link to <a href="https://www.kroger.com/communityrewards">Kroger Community Rewards</a></p>
            <p>Enrollment is used by entering your <strong>phone number</strong> or <strong>plus card number</strong> that is associated with your account. Download detailed instructions <a href='https://drive.google.com/file/d/1QGUurtkFeF1KNiSz41W-d0sw071YQBt3/view?usp=sharing' target={'_blank'}>here</a>.</p>
            <p>Also, please be sure to pass this on to your friends or other theatre production cast/crew to help raise money for our productions! Thanks</p>

            <hr></hr>
            <h3>Amazon Wish List</h3>
            <p>Spotlight Players often uses Amazon to buy a multitude of different things. From name tags for auditions, to raffle tickets for 50/50, to props, and costumes, there are a lot of things that we are always looking for and could use help to keep the price of membership down.</p>
            <p>Please visit our <a href="https://www.amazon.com/hz/wishlist/ls/6YGNUR8AZGTQ/ref=nav_wishlist_lists_2" target={'_blank'}>Amazon Wish list</a> to view current items we are trying to add to our inventory.</p>
          
            <hr></hr>
            <h3>Advertise in our programs</h3>
            <p>It is great to support local businesses by having their ads in our programs. Please stay tuned for our upcoming season/show pricing.</p>
          </div>
        </div>
    </PageContent>    
  </>);
}

export default Support