export default function BoardMeetings({dates}) {
    return (
      <section className="section-xs">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h4>2023 Board Meetings - 7-10PM</h4>
                    <p>Due to COVID-19, all meetings are remote unless otherwise specified. If you would like to attend a meeting, please contact the President and Secretary at least 2 weeks prior to the board meeting and an invite will be sent to you.</p>
                </div>
            </div>
            <div className="row">
            {dates.map((date, index) => (
              <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0" key={index}>
                <div className="media">
                  <div className="media-body">
                    <h6 className="mt-0">
                      {date.month} {date.date}
                    </h6>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-12 mt-4 order-4">
              <div className="border-bottom border-primary" />
            </div>
          </div>
        </div>
      </section>
    );
}