import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types";

const castRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <p style={{"whiteSpace":"pre-wrap"}}>{children}</p>,
    },
};

export default function Cast({isCast,cast}){
  let listTitle = "Character List"
  if(isCast){
    listTitle = "Cast List"
  }
  return(
    <div className="row">
      <div className="col-12 mb-50">
        <h3>{listTitle}</h3>
        {documentToReactComponents(cast,castRenderOptions)}
      </div>
    </div>
  );
};
