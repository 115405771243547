import {useState,useEffect} from 'react';
import { useParams } from "react-router-dom";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import ContentfulUtil from "../util/ContentfulUtil";
import Location from '../components/show/Location';
import Dates from '../components/show/Dates';
import Cast from "../components/show/Cast";
import PageTitle from '../components/PageTitle';
import PageContent from '../components/PageContent';
import Show from "../model/Show";
import NotFound from './NotFound';

export default function SingleShow() {
    const { id } = useParams();
    const [show, setShow] = useState({});
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const singleEntry = await ContentfulUtil.getClient().getEntries({
            content_type: 'show',
            limit: 1,
            ['fields.url']: id
          });
          setShow(new Show(singleEntry.items[0]));
        } catch (error) {
          console.error('Error fetching show details:', error);
          setShow({error:true})
        }
      };
  
      fetchData();
    }, [id]);



    if (!show.fields && !show.error){
      return <>
        <PageTitle>
          Loading Show...
        </PageTitle>
        <PageContent>Loading Show...</PageContent>
      </>
    }

    if(show.error){
      return <NotFound></NotFound>
    }

    const descRenderOptions = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p className="text-lighten">{children}</p>,
        },
    };

    return (<>
    <PageTitle>
      <div className="list-inline-item text-white h3 font-secondary nasted">
        {show.fields.name}
      </div>
      {documentToReactComponents(show.fields.description,descRenderOptions)}
    </PageTitle>
    <PageContent>
      <div className="row">
        <div className="col-12">
          <h2 className="section-title">{show.fields.name}</h2>
        </div>
        <div className="col-12 mb-4">
          <img
            src={show.fields.mainImage.fields.file.url}
            alt={show.fields.mainImage.fields.description}
            className="img-fluid w-100"
          />
        </div>
      </div>
      <div className="row align-items-center mb-5">
        <div className="col-lg-9">
          <ul className="list-inline">
            <li className="list-inline-item mr-xl-5 mr-4 mb-3 mb-lg-0">
              <Location location={show.fields.location}></Location>
            </li>
            <li className="list-inline-item mr-xl-5 mr-4 mb-3 mb-lg-0">
              <Dates dates={show.performances} text="PERFORMANCE DATES"></Dates>
            </li>
            {show.canShowAuditions ? 
            <li className="list-inline-item mr-xl-5 mr-4 mb-3 mb-lg-0">
              <Dates dates={show.auditions} text="AUDITION DATES"></Dates>
            </li>:<></>}
          </ul>
        </div>
        
        {show.canShowTickets ? 
        <a href={show.fields.ticketPurchaseUrl} className="btn btn-primary" style={{marginBottom:"25px"}}>
          Buy Tickets
        </a>:<></>}

        <div className="col-12 mt-4 order-4">
          <div className="border-bottom border-primary" />
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-50">
            {documentToReactComponents(show.fields.infoContent)}
        </div>
      </div>
      
        <div className="col-12 mt-4 order-4">
            <div className="border-bottom border-primary" />
        </div>

        {show.fields.castList ? <Cast cast={show.fields.castList} isCast={show.fields.displayCastList}></Cast>:<></>}
    </PageContent>
  </>);
}