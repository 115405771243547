export default function Location({location}){
    return(
        <div className="d-flex align-items-center">
            <i className="ti-location-pin text-primary icon-md mr-2" />
            <div className="text-left">
            <h6 className="mb-0">LOCATION</h6>
            <p className="mb-0">{location}</p>
            </div>
        </div>
    );
}
