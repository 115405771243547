import {useState,useEffect} from 'react';

import Event from '../../components/Event';
import ContentfulUtil from "../../util/ContentfulUtil";
import PageTitle from '../../components/PageTitle';
import PageContent from '../../components/PageContent';
 
function JrPlayers() {
  
  const [jrSeason, setJrSeason] = useState([]);

  useEffect(()=>{
    const fetchData = async () => {
        const currentSeason = await ContentfulUtil.getCurrentSeason();
        const filteredSeason = currentSeason.filter(show => show.fields.productionGroup == "Spotlight Jr. Players")
        setJrSeason(filteredSeason)
    };
  
    fetchData();
  },[])

  return (<>
    <PageTitle>
      Jr. Players
    </PageTitle>
    <PageContent>
      <div className="row">
        <div className="col-12">
          <img className="img-fluid w-100 mb-4" src="images/MoanaRehearsalBanner.png" alt="about image" />
          <h4>Spotlight Jr Players</h4>
          <p>Spotlight Players is <strong>THRILLED</strong> to have the <strong>Spotlight Jr Players</strong> as part of our group.  The goal of Spotlight Jr Players is to give all those ages 18 and under and chance to shine on stage.  We are also developing a program for the older teens to transition into Non-Acting positions such as Stage Managing, Directing, Producing, as well as the technical side with lighting, sound, and props.</p>
          <h4>Requirements</h4>
          <p>This part is simple….you come and audition, like any other Spotlight Players show.  The only requirement to be involved in one of our shows is you become a member.  In 2018, we are starting our Child Memberships that cover all of our Spotlight Players shows, including the Spotlight Jr Players productions.  Here is how it works….</p>
          <p>For the standard membership rate (currently at $25 / year), the child membership will cover 1 parent / guardian and 1 child.  It will be $5 for each additional child after the first one.  You can find more information including an FAQ on our <a href="#/become-a-member">Membership page</a>.</p>
          <p>When you become a member you never have to pay again in that membership year (which runs from June 1 to May 31 of the following year) and you can audition and be cast in as many Spotlight Players shows as you would like.</p>
        </div>
      </div>
      <div className="row bg-gray">
        <h4 className="col-12" style={{paddingTop:"15px"}}>Jr Players Current Season Productions</h4>
        <div className="row justify-content-center" style={{width:"100%"}}>
          {jrSeason.map((event)=>{
              return <Event 
                key={event.sys.id}
                title={event.fields.name} 
                location={event.fields.location}  
                firstDay={event.firstPerformanceDate}
                imgSrc={event.fields.squareImage.fields.file.url} 
                production={event.fields.productionGroup} 
                link={event.getLink()} 
                dates={event.performances}>
              </Event>
          })}
        </div>
      </div>
    </PageContent>
  </>);
}

export default JrPlayers