import {useState,useEffect} from 'react';

import ContentfulUtil from '../../util/ContentfulUtil';
import PageTitle from '../../components/PageTitle';
import PageContent from '../../components/PageContent';

import PastRow from '../../components/pastSeasons/PastRow';
import WayPastSeasonSection from '../../components/pastSeasons/WayPastSeasonSection';

function PastSeasons() {
  const [pastSeasons, setPastSeasons] = useState([]);

  useEffect(()=>{
    ContentfulUtil.getClient().getEntries({
        content_type:'season',
        order: 'fields.seasonTitle',
        'fields.seasonStatus2':'Past'
    }).then(function (entries) {
        setPastSeasons(entries.items.reverse())
    });
},[])

  const wayPastSeasons = [
    {
      season: '2015-2016',
      shows: ['The Producers', 'Almost, Maine', 'Guys and Dolls', 'Honk!', "Winnie-The-Pooh", "Old Ringers"]
    },
    {
      season: '2014-2015',
      shows: ['The King And I', 'Steel Magnolias', 'Get Smart', 'Young Frankenstein', "Harry's Hotter at Twilight"]
    },
    {
      season: '2013-2014',
      shows: ['Hairspray', 'Death at Dinner', "I Love You, You're Perfect, Now Change", 'The Drowsy Chaperone', 'Aladdin and His Wonderful Magical Lamp', 'Father Bingo', 'The Geezer Games', "Murder’s In the Heir"]
    },
    {
      season: '2012-2013',
      shows: ['My Fair Lady', 'The Glass Menagerie', 'Barnum', 'Old Hams', 'One Foot in the Gravy', 'Gangster Priest', 'Flapper']
    },
    {
      season: '2011-2012',
      shows: ['The Music Man', 'I Hate Hamlet', 'Trouble in Silver City', 'Cheaper By The Dozen', 'Into The Woods', 'Thoroughly Modern Millie', 'Santa Lives!, Yes Virginia, There Is a Santa Clause', 'A Midsummer Night’s Dream – The Musical', 'The Oz Monologues', 'The Little Mermaid', 'Murder Can Be Habit Forming']
    },
    {
      season: '2010-2011',
      shows: ['Kiss Me, Kate', 'Proposals', 'Harvey', 'Once Upon a Mattress', 'Curse of the Hopeless Diamond', 'Tintypes']
    }
  ];

  return (<>
    <PageTitle>
      <div>Past Seasons</div>
      <p className="text-lighten">
        See past productions from Spotlight Players, Spolight Jr.
        Players, and Spotlight Still Got It Players.
      </p>
    </PageTitle>
    <PageContent>
      <section className="section-xs">
        {pastSeasons.map((season, index)=>{
          if (pastSeasons.length === index + 1) {
            return <PastRow key={season.sys.id} season={season} last={true}></PastRow>
          } else {
            return <PastRow key={season.sys.id} season={season} last={false}></PastRow>
          }
        })}
      </section>
      {wayPastSeasons.map((wayPastSeason, index) => (
        <WayPastSeasonSection 
          key={index} 
          season={wayPastSeason.season} 
          shows={wayPastSeason.shows} 
          last={index==wayPastSeasons.length-1?false:true}
        />
      ))}
    </PageContent>
  </>);
}


export default PastSeasons;
