import {useState,useEffect} from 'react';

import Event from '../../components/Event';
import ContentfulUtil from '../../util/ContentfulUtil';
import PageTitle from '../../components/PageTitle';
import PageContent from '../../components/PageContent';

function Auditions() {  
    const [auditions, setAuditions] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
          try {
              const currentSeason = await ContentfulUtil.getCurrentSeasonByStatus();
              const filteredAuditions = currentSeason.filter(show => show.lastAuditionDate >= new Date())
              setAuditions(filteredAuditions);
          } catch (error) {
              console.error('Error fetching show details:', error);
          }
      };
    
      fetchData();
    }, []);

    let noAuditions="";
    if(auditions.length===0){
        noAuditions = "There are no auditions at the moment, check back soon!";
    }

    return (<>
        <PageTitle>
            Auditions
        </PageTitle>
        <PageContent>
            <div className="row justify-content-center">
                { noAuditions }
                {auditions.map((event)=>{ 
                    return <Event 
                        key={event.sys.id}
                        title={event.fields.name} 
                        location={event.fields.location}  
                        firstDay={event.firstAuditionDate}
                        imgSrc={event.fields.squareImage.fields.file.url} 
                        production={event.fields.productionGroup} 
                        link={event.getLink()} 
                        dates={event.auditions}>
                    </Event>
                })}
            </div>
        </PageContent>
    </>);
}

export default Auditions