export default function PastRow ({season}){
    return (
      <div className="container" style={{marginBottom:"30px"}}>
            <div className="row">
              <div className="col-12">
                <h3 className="mb-4">{season.fields.seasonTitle}</h3>
              </div>
              {season.fields.shows.map((show)=>{
                let name = <h4 className="mt-0">{show.fields.name}</h4>;
                if (show.fields.name.length > 22) {
                    name = <h5 className="mt-0">{show.fields.name}</h5>;
                }

                return (<div key={show.sys.id} className="col-lg-3 col-sm-6 mb-4 mb-lg-0">
                <div className="media">
                  <img
                    className="mr-3 img-fluid ps-image"
                    src={show.fields.squareImage.fields.file.url}
                  />
                  <div className="media-body">
                    <a href={"#/show/" + show.fields.url}>
                      {name}
                    </a>
                  </div>
                </div>
              </div>)
              })}
            </div>
            <div className="col-12 mt-4 order-4">
              <div className="border-bottom border-primary" />
            </div>
      </div>

    );
  }