function Footer(){
    return(
        <footer>
            <div className="copyright py-4 bg-footer">
              <div className="container">
                <div className="row">
                  <div className="col-sm-7 text-sm-left text-center">
                    <p className="mb-0 text-primary">Spotlight Players <br/>P.O. Box 87821 Canton, MI 48187<br/><a className="footerLink" href="mailto:spotlightplayers.board@gmail.com">spotlightplayers.board@gmail.com</a></p>
                  </div>
                  <div className="col-sm-5 text-sm-right text-center">
                    <ul className="list-inline">
                      <li className="list-inline-item"><a className="d-inline-block p-2" href="https://www.facebook.com/SpotlightPlayersMI" target={"_blank"}><i className="ti-facebook text-primary" /></a></li>
                      <li className="list-inline-item"><a className="d-inline-block p-2" href="https://www.tiktok.com/@spotlightplayersmi" target={"_blank"}><i className="fa-brands fa-tiktok" style={{"color": "#ffbc3b"}}></i></a></li>
                      <li className="list-inline-item"><a className="d-inline-block p-2" href="https://www.instagram.com/spotlightplayers_mi/" target={"_blank"}><i className="ti-instagram text-primary" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>
    );
}
export default Footer