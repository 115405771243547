import {useState,useEffect} from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS,INLINES } from "@contentful/rich-text-types";

import ContentfulUtil from "../util/ContentfulUtil";

export default function Announcements(){
    const [announcements, setAnnouncements] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const today = new Date();
                const entries = await ContentfulUtil.getClient().getEntries({
                    content_type: 'announcement',
                    limit: 4,
                    ["fields.expirationDate[gt]"]:today
                  });
                setAnnouncements(entries.items);
            } catch (error) {
                console.error('Error fetching show details:', error);
            }
        };
    
        fetchData();
    }, []);

    const renderOptions = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p className="text-white">{children}</p>,
            [INLINES.HYPERLINK]: (node, children) => <a className="announcementLink" href={node.data.uri}>{node.content[0].value}</a>
        },
    };

    if (announcements.length == 0){
        return <></>
    }

    return(
        <div className="bg-primary text-center announcement">
            {announcements.map((announcement)=>{
                return documentToReactComponents(announcement.fields.text,renderOptions)
            })}
        </div>
    );
}
