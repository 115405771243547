import {dateCombiner} from '../../util/DateUtil';

export default function Dates({dates,text}){
    let datesArray = [];
    let formattedDates = dateCombiner(dates);
    for (const month in formattedDates) {
        for (const time in formattedDates[month]) {
            datesArray.push(`${month} ${formattedDates[month][time]} at ${time}`);
        }
    }

    return(
        <div className="d-flex align-items-center">
            <i className="ti-calendar text-primary icon-md mr-2" />
            <div className="text-top">
            <h6 className="mb-0">{text}</h6>
                {datesArray.map((date,i)=>{
                    return <p key={i} className="mb-0">{date}</p>
                })}
            </div>
        </div>
    );
}
