import { createClient } from "contentful";
import Show from "../model/Show";

class ContentfulUtil {
  constructor() {
    if (ContentfulUtil.instance) {
      throw new Error("New instance cannot be created!!");
    }

    this.client = createClient({
      space: 'ae4cmm9kc6vt',
      accessToken: 'LVhchEDWOi01FWZzEnDUz__D-jWownviI7Yl_9Pv3fw',
    });

    ContentfulUtil.instance = this;
    this.currentSeason = null;
    this.pastSeason = null;
    this.futureSeason = null;
  }

  getClient() {
    return this.client;
  }

  #isPublished(entity) {
    return !!entity.fields
  }

  async getCurrentSeason() {
    if (this.currentSeason) {
      return this.currentSeason;
    }

    try {
      const entries = await this.client.getEntries({
        content_type: 'season',
        'fields.currentSeason': true
      });

      const season = [];
      for (const entry of entries.items) {
        for (const show of entry.fields.shows) {
          if (this.#isPublished(show)){
            season.push(new Show(show))
          } 
        }
      }

      this.currentSeason = season;
      return this.currentSeason;
    } catch (error) {
      console.error('Error retrieving current season:', error);
      return null;
    }
  }

// New section
  async getPastSeasonsByStatus() {
    if (this.pastSeason) {
      return this.pastSeason;
    }

    try {
      const entries = await this.client.getEntries({
          content_type: 'season',
          order: 'fields.seasonTitle',
          'fields.seasonStatus2': 'Past'
      }).then(function (entries) {
          return entries.items.reverse()
      });

      return entries;
    } catch (error) {
      console.error('Error retrieving past season:', error);
      return null;
    }
  }

  async getCurrentSeasonByStatus() {
    if (this.currentSeason) {
      return this.currentSeason;
    }

    try {
      const entries = await this.client.getEntries({
        content_type: 'season',
        'fields.seasonStatus2': 'Current'
      });

      const season = [];
      for (const entry of entries.items) {
        for (const show of entry.fields.shows) {
          if (this.#isPublished(show)){
            season.push(new Show(show))
          } 
        }
      }

      this.currentSeason = season;
      return this.currentSeason;
    } catch (error) {
      console.error('Error retrieving current season:', error);
      return null;
    }
  }

  async getFutureSeasonByStatus() {
    if (this.futureSeason) {
      return this.futureSeason;
    }

    try {
      const entries = await this.client.getEntries({
        content_type: 'season',
        'fields.seasonStatus2': 'Future'
      });

      const season = [];
      for (const entry of entries.items) {
        for (const show of entry.fields.shows) {
          if (this.#isPublished(show)){
            season.push(new Show(show))
          } 
        }
      }

      this.futureSeason = season;
      return this.futureSeason;
    } catch (error) {
      console.error('Error retrieving future season:', error);
      return null;
    }
  }
}

const instance = new ContentfulUtil();
export default instance;
