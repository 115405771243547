import {dateCombiner} from "../util/DateUtil"
import React from "react";

export default function Event({dates, title, imgSrc, imgAlt, firstDay, location, production, text, link}){
    const performances = [];
    let formattedPerformances = null;
    const date = new Date(firstDay);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    
    if(dates){
        let formattedDates = dateCombiner(dates);

        for (const month in formattedDates) {
            for (const time in formattedDates[month]) {
                performances.push(`${month} ${formattedDates[month][time]} at ${time}`);
            }
        }

        formattedPerformances = performances.map((performance,index)=>{
            return <React.Fragment key={index}><br/><i className="ti-calendar text-primary mr-2" />{performance}</React.Fragment>
        })
    }
    
    let titleFormatted = <h4 className="card-title h4 text-secondary">{title}</h4>;
    if (title.length > 22) {
        titleFormatted = <h5 className="card-title h5 text-secondary">{title}</h5>;
    }
    
    let buttonLink = "#"+link;
    let pattern = /http/;
    if(pattern.test(link)){
        buttonLink = link
    }

    let buttonText = "Details";

    if(text){
        buttonText = text;
    }

    let buttonDetails = <></>

    if(link) {
        buttonDetails = <div className="btn-center"><a href={buttonLink} className="btn btn-primary btn-sm">{buttonText}</a></div>
    }

    return(
        <div className="col-lg-4 col-sm-6 mb-5 mb-lg-0" style={{padding:"20px"}}>
            <div className="card border-0 rounded-0 hover-shadow">
            <div className="card-img position-relative">
                <img className="card-img-top rounded-0" src={imgSrc} alt={imgAlt} />
                <div className="card-date h5"><span>{day}</span><br />{month}</div>
            </div>
            <div className="card-body">
                {titleFormatted}
                <p><i className="ti-location-pin text-primary mr-2" />{location}<br/><i className="ti-user text-primary mr-2" />{production}{formattedPerformances}</p>
                {buttonDetails}
            </div>
            </div>
        </div>
    );
}
