import './App.css';
import Header from './Header';
import Footer from './Footer';
import Home from "./pages/Home";
import AboutUs from './pages/aboutUs/AboutUs';
import SingleShow from './pages/SingleShow';
import CurrentSeason from './pages/CurrentSeason';
import PastSeasons from './pages/aboutUs/PastSeasons';
import FutureSeason from './pages/FutureSeason';
import Auditions from './pages/getInvolved/Auditions';
import Tickets from './pages/Tickets';
import Volunteer from './pages/getInvolved/Volunteer';
import Support from './pages/getInvolved/Support';
import ProductionTeam from './pages/getInvolved/ProductionTeam';
import JrPlayers from './pages/aboutUs/JrPlayers';
import PoliciesAndPractices from './pages/aboutUs/PoliciesAndPractices';
import BecomeAMember from './pages/getInvolved/BecomeAMember';
import NotFound from './pages/NotFound';

import { Routes, Route } from "react-router-dom"; 

function App() {
  return (<>     
    <Header />
        <Routes>
            <Route path="about" element={<AboutUs />} />
            <Route path="jrplayers" element={<JrPlayers />} />
            <Route path="past-seasons" element={<PastSeasons />} />
            <Route path="next-season" element={<FutureSeason />} />
            <Route path="policies-and-practices" element={<PoliciesAndPractices />} />
            <Route path="auditions" element={<Auditions />} />
            <Route path="volunteer" element={<Volunteer />} />
            <Route path="donate" element={<Support />} />
            <Route path="production-team" element={<ProductionTeam />} />
            <Route path="become-a-member" element={<BecomeAMember />} />
            <Route path="tickets" element={<Tickets />} />
            <Route path="show/:id" element={<SingleShow />} />
            <Route path="show" element={<CurrentSeason />} />
            <Route path="current-season" element={<CurrentSeason />} />
            <Route exact path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    <Footer />
  </>);
}

export default App;