import {useState,useEffect} from 'react';

import Event from '../components/Event';
import ContentfulUtil from "../util/ContentfulUtil";
import PageTitle from '../components/PageTitle';
import PageContent from '../components/PageContent';

function CurrentSeason() {   
    const [currentSeason, setCurrentSeason] = useState([]);
    
    useEffect(()=>{
        const fetchData = async () => {
            const currentSeason = await ContentfulUtil.getCurrentSeasonByStatus();
            setCurrentSeason(currentSeason)
        };
        fetchData();
    },[])

    return (<>
        <PageTitle>Current Season</PageTitle>
        <PageContent>
            <div className="row justify-content-center">
                {currentSeason.map((event)=>{                  
                    return <Event 
                        key={event.sys.id}
                        title={event.fields.name} 
                        location={event.fields.location}  
                        firstDay={event.firstPerformanceDate}
                        imgSrc={event.fields.squareImage.fields.file.url} 
                        production={event.fields.productionGroup} 
                        link={event.getLink()} 
                        dates={event.performances} >
                    </Event>
                })}
            </div>
        </PageContent>
    </>);
}

export default CurrentSeason