import PageTitle from '../../components/PageTitle';
import PageContent from '../../components/PageContent';

function BecomeAMember() {
  return (<>
    <PageTitle>
      Become A Member     
      <p className="text-lighten">Support The Spotlight Players by becoming a member today!</p>
    </PageTitle>
    <PageContent>
        <div className="row">
          <div className="col-12">         
            <p>Applications are accepted year-round. We welcome new talent and individuals who desire to help out behind-scenes, as well. Please be advised that the Code of Conduct and Background Check Authorization are also forms mandatory for membership in Spotlight Players.</p>
            <p>Renew your membership or become a member (we are always welcoming new members) for just $25</p>
            <p>Please come back soon for the 2023/2024 Membership application.</p>

            <h4>MEMBERSHIP POLICY</h4>
            <p>Learn about the particulars of our membership and what it entails. We hope that you will be encouraged to renew your membership from year to year, regardless of participation in the shows for any given season. Membership renewal helps support our efforts in an on-going basis. View our Membership Policy <a href="https://drive.google.com/file/d/1jibuT8JQdYBt7Qu_yy0RAaAfl-ReOcZa/view?usp=sharing" target={"_blank"}>here</a>.</p>

            <h4>CODE OF CONDUCT POLICY</h4>
            <p>As a community organization that is run by volunteers, we believe it is important to insist on a certain standard of behavior from our members. We want our group represented in a positive manner both on and off the stage. We also want to respect those volunteers who work so hard and donate so much of their time and resources to the interests of Spotlight Players. Signing this document is mandatory for membership in Spotlight Players. Download our Code of Conduct <a href="https://drive.google.com/file/d/1AimALZGsn1H1D-H4aek8p8DBASLb9IOH/view?usp=sharing" target={"_blank"}>here</a>.</p>

            <h4>CANTON TOWNSHIP BACKGROUND FORM</h4>
            <p>Spotlight Players uses Canton Township facilities for all of our rehearsals and performances. All members of Spotlight are required to submit a Canton Township Leisure Services Background Form with your membership. This form is to be completed once per year and is submitted directly to Canton Township, they are not kept by Spotlight Players. Download Canton Township background check authorization form <a href="https://drive.google.com/file/d/1JOdtWsWR46EsW8URcybKvDaGrnRgvCZa/view?usp=sharing" target={"_blank"}>here</a></p>

            <p>Please also review Canton Leisure Services Code of Conduct and suspension policy <a href="https://drive.google.com/file/d/1jcW78gFaFrTJTxVbt6kUoiWN3YPItW3M/view?usp=sharing" target={"_blank"}>here</a>.</p>
             
          </div>
        </div>
    </PageContent>    
  </>);
}

export default BecomeAMember