import React from 'react';

import PageTitle from '../../components/PageTitle';
import PageContent from '../../components/PageContent';
import VolunteerCard from '../../components/volunteer/VolunteerCard';

function Volunteer() {
  return (
    <>
      <PageTitle>
        Why Volunteer?
        <p className="text-lighten">Community theater allows community members to be involved in a theater in ways that interest them.</p>
      </PageTitle>
      <PageContent>
        <div className="row justify-content-center">
          {volunteerData.map((data, index) => (
            <VolunteerCard key={index} {...data} />
          ))}
        </div>
      </PageContent>
    </>
  );
}

const volunteerData = [
  {
    id: 'performer',
    imageSrc: 'images/Performer.png',
    title: 'PERFORM',
    position: 'Volunteer',
    description: (
      <>
        <ul className="list-styled">
          <li>2 audition dates available for each show</li>
          <li>No callbacks</li>
          <li>Ages 6 & Over (6-18 for Jr. shows)</li>
          <li>No experience necessary</li>
          <li>Time Commitment:</li>
          <ul>
            <li>Rehearsals (up to 4 days a week)</li>
            <li>Saturday Set Builds</li>
            <li>Tech Week</li>
            <li>Performances</li>
            <li>Sunday Strike</li>
          </ul>
        </ul>
      </>
    ),
    buttonText: 'See Upcoming Auditions',
    buttonLink: '#/auditions',
  },
  {
    imageSrc: 'images/Set.png',
    title: 'SET BUILD & DESIGN',
    position: 'Volunteer',
    description: (
      <>
        <ul className="list-styled">
          <li>Design set layout with production team</li>
          <li>Lead Set Build days</li>
          <li>Some Carpentry Skills preferred - training will be provided by theater tech</li>
          <li>Lead Load In and Strike of set pieces</li>
          <li>Time Commitment:</li>
          <ul>
            <li>Production Meetings</li>
            <li>Saturday Set Builds (4 per show)</li>
            <li>Load In</li>
            <li>Strike</li>
          </ul>
        </ul>
      </>
    ),
    buttonText: 'Join Our Volunteer List',
    buttonLink: 'https://forms.gle/zAcQasjRKWZxPWjaA',
    target: '_blank',
  },
  {
    imageSrc: 'images/Lights.png',
    title: 'LIGHT DESIGN',
    position: 'Volunteer',
    description: (
      <>
        <ul className="list-styled">
          <li>Program lights</li>
          <li>Run lights for show</li>
          <li>Call spotlight queues</li>
          <li>No experience necessary - training will be provided by theater tech</li>
          <li>Time Commitment:</li>
          <ul>
            <li>Production Meetings</li>
            <li>Tech Week</li>
            <li>Performances</li>
            <li>Strike</li>
          </ul>
        </ul>
      </>
    ),
    buttonText: 'Join Our Volunteer List',
    buttonLink: 'https://forms.gle/zAcQasjRKWZxPWjaA',
    target: '_blank',
  },
  {
    imageSrc: 'images/Sound.png',
    title: 'SOUND',
    position: 'Volunteer',
    description: (
      <>
        <ul className="list-styled">
          <li>Design Mic plot for actors with solos or lines</li>
          <li>No experience required - training will be provided by theater tech</li>
          <li>Set up mic equipment during load in</li>
          <li>Mic actors and run mic check during tech rehearsals and shows</li>
          <li>Control mic levels and additional sound cues</li>
          <li>Time Commitment:</li>
          <ul>
            <li>Production Meetings</li>
            <li>Tech Week</li>
            <li>Performances</li>
            <li>Strike</li>
          </ul>
        </ul>
      </>
    ),
    buttonText: 'Join Our Volunteer List',
    buttonLink: 'https://forms.gle/zAcQasjRKWZxPWjaA',
    target: '_blank',
  },{
    imageSrc: 'images/StageManager.png',
    title: 'STAGE MANAGE',
    position: 'Volunteer',
    description: (
      <>
        <ul className="list-styled">
          <li>Record blocking and Scene Change notes</li>
          <li>Communicate between Director, Lights, Sound, Stage Crew and Cast</li>
          <li>Lead load in and Strike</li>
          <li>Call the show and backstage cues</li>
          <li>Verify attendance for tech week and shows</li>
          <li>Time Commitment:</li>
          <ul>
            <li>Production Meetings</li>
            <li>Rehearsals as needed</li>
            <li>Tech Week</li>
            <li>Shows</li>
          </ul>
        </ul>
      </>
    ),
    buttonText: 'Join Our Volunteer List',
    buttonLink: 'https://forms.gle/zAcQasjRKWZxPWjaA',
    target: '_blank',
  },
  {
    imageSrc: 'images/StageCrew.png',
    title: 'STAGE CREW',
    position: 'Volunteer',
    description: (
      <>
        <ul className="list-styled">
          <li>Move Set Pieces</li>
          <li>Fly legs and set pieces - training provided</li>
          <li>Run Spotlight - training provided</li>
          <li>Time Commitment:</li>
          <ul>
            <li>Tech Week</li>
            <li>Shows</li>
          </ul>
        </ul>
      </>
    ),
    buttonText: 'Join Our Volunteer List',
    buttonLink: 'https://forms.gle/zAcQasjRKWZxPWjaA',
    target: '_blank',
  },
  {
    imageSrc: 'images/Costumes.png',
    title: 'COSTUMES',
    position: 'Volunteer',
    description: (
      <>
        <ul className="list-styled">
          <li>Design Costume plot with production team</li>
          <li>Take actor measurements</li>
          <li>Organize costume pickup/return from storage or rental locations</li>
          <li>Purchase additional costumes</li>
          <li>Modify/Make Costumes to fit actors</li>
          <li>Sewing experience preferred</li>
          <li>Time Commitment:</li>
          <ul>
            <li>Production Meetings</li>
            <li>Rehearsals as needed</li>
            <li>Load In</li>
            <li>Strike</li>
          </ul>
        </ul>
      </>
    ),
    buttonText: 'Join Our Volunteer List',
    buttonLink: 'https://forms.gle/zAcQasjRKWZxPWjaA',
    target: '_blank',
  },
  {
    imageSrc: 'images/Props.png',
    title: 'PROPS',
    position: 'Volunteer',
    description: (
      <>
        <ul className="list-styled">
          <li>Review all props needed for shows</li>
          <li>Collect props from storage</li>
          <li>Buy/Rent props</li>
          <li>Organize props on prop tables</li>
          <li>Time Commitment:</li>
          <ul>
            <li>Production Meetings</li>
            <li>Load In</li>
            <li>Strike</li>
          </ul>
        </ul>
      </>
    ),
    buttonText: 'Join Our Volunteer List',
    buttonLink: 'https://forms.gle/zAcQasjRKWZxPWjaA',
    target: '_blank',
  },{
    imageSrc: 'images/FrontOfHouse.png',
    title: 'FRONT OF HOUSE',
    position: 'Volunteer',
    description: (
      <>
        <ul className="list-styled">
          <li>Organize Front of House activities and Volunteers</li>
          <li>Purchase/Receive donations for raffles if requested</li>
          <li>Bring Supplies for 50/50, raffles, shout outs as required</li>
          <li>Organize Front of House table</li>
          <li>Announce winners at intermission</li>
          <li>Time Commitment:</li>
          <ul>
            <li>Production Meetings</li>
            <li>Shows</li>
          </ul>
        </ul>
      </>
    ),
    buttonText: 'Join Our Volunteer List',
    buttonLink: 'https://forms.gle/zAcQasjRKWZxPWjaA',
    target: '_blank',
  },
  {
    imageSrc: 'images/Musicians.png',
    title: 'MUSICIAN',
    position: 'Volunteer/Stipend',
    description: (
      <>
        <ul className="list-styled">
          <li>Play in the pit</li>
          <li>This can be on a volunteer or stipend position depending on pit size and show</li>
          <li>Time Commitment:</li>
          <ul>
            <li>Sitz Probe Rehearsal</li>
            <li>Tech Week</li>
            <li>Shows</li>
          </ul>
        </ul>
      </>
    ),
    buttonText: 'Join Our Musician List',
    buttonLink: 'https://docs.google.com/forms/d/e/1FAIpQLSceeKj8xPs_FIyMrmdjNtndL2INDeGlcwDyQAk0_tYxLKvU1w/viewform?usp=sf_link',
    target: '_blank',
  },
  {
    imageSrc: 'images/ProductionTeam.png',
    title: 'PRODUCTION TEAM',
    position: 'Stipend Positions',
    description: (
      <>
        <ul className="list-styled">
          <li>We have several paid positions:</li>
          <ul>
            <li>Director</li>
            <li>Music Director</li>
            <li>Producer</li>
            <li>Choreographer</li>
            <li>Musicians</li>
          </ul>
        </ul>
      </>
    ),
    buttonText: 'Learn More',
    buttonLink: '#/production-team',
  }
];

export default Volunteer;