export default function WayPastSeasonSection({season, shows, last }) {
    let border = <div className="col-12 mt-4 order-4">
      <div className="border-bottom border-primary" />
    </div>
    return (
      <section className="section-xs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="mb-4">{season} Season</h3>
            </div>
            {shows.map((show, index) => (
              <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0" key={index}>
                <div className="media">
                  <div className="media-body past-col-ht">
                    <h4 className="mt-0">{show}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
          { last ? border : <></>}
        </div>
      </section>
    );
}