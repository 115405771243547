import {useState,useEffect} from 'react';

import ContentfulUtil from '../util/ContentfulUtil';
import Event from '../components/Event';
import PageTitle from '../components/PageTitle';
import PageContent from '../components/PageContent';
import Announcements from '../components/Announcements';

export default function Home(){
    const [shows, setShows] = useState([]);
    const [futureSeason, setFutureSeason] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentSeason = await ContentfulUtil.getCurrentSeasonByStatus();
                const firstThreeShows = currentSeason.filter(show => show.lastPerformanceDate >= new Date()).slice(0, 3)
                setShows(firstThreeShows);
                const futureSeason = await ContentfulUtil.getFutureSeasonByStatus();
                setFutureSeason(futureSeason);
            } catch (error) {
                console.error('Error fetching show details:', error);
            }
        };
      
        fetchData();
    }, []);

    let upcomingShows = <></> 
    
    if(shows.length == 0 && futureSeason.length == 0){
        upcomingShows = <div className='text-white ml-sm-3 h5'>There are currently no upcoming shows remaining this season, check back soon!</div>
    }

    if(shows.length < 3 && futureSeason.length > 0){
        // upcomingShows = <div>test</div>
        upcomingShows =  <div className="col-lg-4 col-sm-6 mb-5 mb-lg-0" style={{padding:"20px"}}>
        <div className="card border-0 rounded-0 hover-shadow">
        <div className="card-img position-relative">
            <img className="card-img-top rounded-0" src="images/NextSeasonPreview.png" alt="Next Season Preview" />
        </div>
        <div className="card-body">
            <h4 className="card-title h4 text-secondary">Want to know what we have been planning?</h4>
            <p>Here is a preview for our upcoming season &nbsp;</p>
            <div className="btn-center"><a href="#/next-season" className="btn btn-primary btn-sm">Preview Next Season</a></div>
        </div>
        </div>
    </div>
     }

    return(<>
        <PageTitle>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex align-items-center section-title justify-content-between">
                    <h2 className="mb-0 text-nowrap text-primary mr-3">Upcoming Shows</h2>
                    <div className="border-top w-100 border-primary d-none d-sm-block" />
                    <div>
                        <a href="#/current-season" className="btn btn-sm btn-primary-outline ml-sm-3 d-none d-sm-block">see all</a>
                    </div>
                    </div>
                </div>
            </div>
            {/* events */}
            <div className="row justify-content-center"> 
                {shows.map((event)=>{ 
                    return <Event 
                        key={event.sys.id}
                        title={event.fields.name} 
                        location={event.fields.location}  
                        firstDay={event.firstPerformanceDate}
                        imgSrc={event.fields.squareImage.fields.file.url} 
                        production={event.fields.productionGroup} 
                        link={event.getLink()} 
                        dates={event.performances}>
                    </Event>
                })}
                {upcomingShows}
            </div>
            {/* /events */}
            <div className="row">
                <div className="col-12 text-center">
                    <a href="#/current-season" className="btn btn-sm btn-primary-outline d-sm-none d-inline-block">See All</a>
                </div>
            </div>
        </PageTitle>
        <Announcements />
        <PageContent>
            {/* banner-feature */}
            <section className="section">
                <div className="row no-gutters">
                    <div className="col-xl-4 col-lg-5 align-self-end">
                        <img className="img-fluid w-100" style={{paddingBottom: "50%"}} src="images/involvement.jpg" alt="banner-feature" />
                    </div>
                    <div className="col-xl-8 col-lg-7">
                        <div className="row feature-blocks bg-gray justify-content-between">
                        <div className="col-sm-6 col-xl-5 mb-xl-5 mb-lg-3 mb-4 text-center text-sm-center">
                            <h3 className="mb-xl-4 mb-lg-3 mb-4"><i className="ti-ticket feature-icon vertical-align-middle" /><a href="#/tickets" className="btn btn-primary" data-animation-out="fadeOutDown" data-delay-out={5} data-duration-in=".3" data-animation-in="zoomIn" data-delay-in=".7">Buy Tickets</a></h3>
                            <p>Purchase tickets for upcoming shows</p>
                        </div>
                        <div className="col-sm-6 col-xl-5 mb-xl-5 mb-lg-3 mb-4 text-center text-sm-center">
                            <h3 className="mb-xl-4 mb-lg-3 mb-4"><i className="ti-microphone feature-icon vertical-align-middle" /><a href="#/auditions" className="btn btn-primary" data-animation-out="fadeOutDown" data-delay-out={5} data-duration-in=".3" data-animation-in="zoomIn" data-delay-in=".7">Auditions</a></h3>
                            <p>Interested in auditioning for a show?</p>  
                        </div>
                        <div className="col-sm-6 col-xl-5 mb-xl-5 mb-lg-3 mb-4 text-center text-sm-center">
                            <h3 className="mb-xl-4 mb-lg-3 mb-4"><i className="ti-hand-open feature-icon vertical-align-middle" /><a href="#/volunteer" className="btn btn-primary btn-center   " data-animation-out="fadeOutDown" data-delay-out={5} data-duration-in=".3" data-animation-in="zoomIn" data-delay-in=".7">Be Involved</a></h3>
                            <p>Are you interested in being involved in a show? We are always looking for volunteers to help out with any of our productions!</p>
                        </div>
                        <div className="col-sm-6 col-xl-5 mb-xl-5 mb-lg-3 mb-4 text-center text-sm-center">
                            <h3 className="mb-xl-4 mb-lg-3 mb-4"><i className="ti-money feature-icon vertical-align-middle" /><a href="#/donate" className="btn btn-primary btn-center   " data-animation-out="fadeOutDown" data-delay-out={5} data-duration-in=".3" data-animation-in="zoomIn" data-delay-in=".7">Support</a></h3>
                            <p>Interested in supporting in big or small ways? You can donate or sign up to our rewards programs to help us produce amazing productions!</p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* /banner-feature */}
            {/* about us */}
            <section className="section">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-md-6 order-2 order-md-1">
                    <h2 className="section-title">About Spotlight Players</h2>
                    <p>Spotlight Players is partnered with The Village Theater at Cherry Hill and Ridge Roads in Canton. Our partnership with the theater provides us with the privilege of rehearsing and performing inside the beautiful state-of-the-art facilities that are as unique as they are luxurious. We thank The Village Theater for its support and promotion of our mission statement as we have striven to bring quality live theater experiences to audiences for almost a decade.</p>
                    <a href="#/about" className="btn btn-primary-outline">Learn more</a>
                </div>
                <div className="col-md-6 order-1 order-md-2 mb-4 mb-md-0">
                    <img className="img-fluid w-100" src="images/SPLogo.png" alt="about image" />
                </div>
                </div>
            </div>
            </section>
            {/* /about us */}
            {/* cta */}
            <section className="section bg-primary">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h2 className="section-title text-white">Join a production team!</h2>
                    <a href="#/production-team" className="btn btn-secondary">Apply Now</a>
                </div>
                </div>
            </div>
            </section>
            {/* /cta */}
        </PageContent>
    </>);
}